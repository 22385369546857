<template>
    <div>
        <linkbutton toplabel="Add Guest" @click="startUpdateguest()" v-if="isNew"/>
        <pencilbutton @click="startUpdateguest()" v-if="!isNew" />
        <!-- MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form id="updateguestform" ref="updateguestform" @submit.prevent>
                    <label class="titlelabel">Update Guest</label>
                    <formentry :label="guestfirstNameLabel" isrequired='True' v-model="person.FirstName" />
                    <formentry :label="guestlastNameLabel" isrequired='True' v-model="person.LastName"  />
                   
                    <formentry label="Email" inputtype="email" isrequired="True" v-model="person.EmailAddress" @blur="updateEmail"/>
                    <formentry label="Phone" inputtype="tel" isrequired="False" @blur="leavePhone" v-model="person.PhoneNumber1"/> 
                    <formentry label="Student #" isrequired="True" v-model="person.StudentID"/>
                   
                    <div class="entry" >
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Location</label>
                    </div>
                    <div class="entryTextDiv">
                    <select name="" id="" class='entrySelect' required v-model="person.locationId_Primary"  >
                        <option v-for="loc in LocationList" :key="loc.id" :value="loc.id" >
                            {{loc.name}}
                        </option>
                    </select>
                    </div>
                </div>
                    <smallbutton  @click="saveUpdatedguest()" toplabel="Save" />
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                </form>
                
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import datamethods from '../../services/datamethods'
    import format from '../../style/format'
    import smallbutton from '../controls/button.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import formentry from '../controls/entry.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import pencilbutton from '../controls/pencilbutton.vue'
    import {mapFields} from 'vuex-map-fields'
    export default {
        name:'addguest',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton,
            pencilbutton
        },
        props:{
            guestId:{
                type:Number,
                required:true
            },
            isNew:{
                type:Boolean,
                default:false
            }
            
        },
        mixins:[datamethods,format],
        computed:{
            ...mapFields([
                'responseMessage',
                'pleaseWait'
            ]),
            mapEmailToLocation(){
                if(window.MAP_EMAIL_TO_LOCATION==undefined){return false}
                else{return window.MAP_EMAIL_TO_LOCATION}
            }
        },
        data(){
            return{
                responseMessage:'',
                
                person:{

                },
    
    
                guestfirstNameLabel:'Guest First Name',
                guestlastNameLabel:'Guest Last Name',
                guestPreferredLabel:'Preferred Name (Nickname)',
    
                LocationList:[],
            }
        },
        
        methods:{
            async updateEmail(){
                if(!this.mapEmailToLocation){return}

                console.log('map email to loc!!')
                //call api
                let req = {
                    ResponseString:this.person.EmailAddress
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'mapemailtolocation')
                //handle response
            
                if(response.Successful){
                    this.person.locationId_Primary = response.ResponseInteger

                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            async startUpdateguest(){
                this.getLocations()
                this.newGuest()
                this.message = ''
    
                this.$refs.mymodal.openModal()
            },
            async getLocations(){
          
                //call api
               // this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(null,'locations')
                //handle response
            
                if(response.Successful){
                    this.pleaseWait=false;
                    this.LocationList= response.locationList
                    if(this.LocationList.length>0){
                    //console.log('loc ' + this.location.id)
                    
                    
                    //this.selectLocation()
                    }

                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            async saveUpdatedguest(){
                if(this.$refs.updateguestform.checkValidity()==false){return}
    
                this.person.PhoneNumber1=this.$options.filters.cleanPhone(this.person.PhoneNumber1)
    
                let req = {
                    person:this.person,
                }
                var response = await this.callAPI(req,'updatepersonpos')
                if(response.Successful){
                    this.$refs.mymodal.closeModal()
    
                    var lookupstring =this.person.StudentID 
                    this.$emit('refresh',lookupstring)
              
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            newGuest(){
                this.person={
                    ID:-1,
                    FirstName:'',
                    LastName:'',
                    PhoneNumber1:'',
                    locationId_Primary:-1,
                    StudentID:'',
                    IsActive:true
                }
            },
            leavePhone(){
    
                this.person.PhoneNumber1=this.$options.filters.formatPhone(this.person.PhoneNumber1);
                
                
            },
           
        }
    }
    </script>
    
    <style>
    .entryCheckLabel{
        margin:0px 0px 0px 5px;
        font-size:medium;
        text-align: left;  
        max-width: 420px;
    }
    .entryCheckLabelbold{
        margin:0px 0px 0px 5px;
        font-size:medium;
        text-align: left;  
        max-width: 420px;
        font-weight:bold;
    }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    