<template>
<div class="pmtdiv">
    <smallbutton :toplabel="name" @click="start()" v-if="showButton" :buttonclass="buttonClass"/>
    <linkbutton :toplabel="name" @click="start()" v-if="!showButton"/>
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="balform">
                
                <label class="titlelabel">{{name}}</label>
                <br/>
                <div>
                    <label v-if="ticketList.length==0">There are no suspended tickets</label>
                </div>
                   
                    <div :class="ticketclass(t)" v-for="t in ticketList" :key="t.id" :value="t.id" @click="selectTicket(t.id)">
                        <div class="susprecord">
                            <label class="text subtitle" for>{{t.guestName}}</label>
                            <ticketdetail :ticketId="t.id"/>
                            <confirm :showImageButton="trueParam" :body="getRemoveString(t.guestName)" title="Remove Ticket" @yes="Remove(t.id)" v-if="t.authorizedPaymentCount==0"/>
                            <confirm  :showLink="trueParam" :body="getVoidString(t.guestName)" title="Void Ticket" 
                                @yes="checkprocess_terminaltest(t.id)" v-if="t.authorizedPaymentCount>0"/>
                        </div>
                    </div>        
                
                <smallbutton  @click="go()" toplabel="Go To Ticket" :disabled="ticketList.length==0"/>
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
                <securityoverride ref="override"  @overridesuccessful="overrideSuccessful"/>
            </form>
            
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import format from '../../style/format.js'
import smallbutton from '../controls/button.vue'
import linkbutton from '../controls/linkbutton.vue'
import actionmodal from '../controls/actionmodal.vue'
import formentry from '../controls/entry.vue'
import datamethods from '../../services/datamethods.js'
import confirm from '../actions/confirm.vue'
import securityoverride from '../actions/securityoverride.vue'
    import securityhelper from '../../services/securityhelper.js'
    import ticketdetail from '../actions/ticketdetail.vue'
export default {
    name:'suspendedtickets',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton,
        confirm,
        securityoverride,
        ticketdetail
    },
    props:{
       
        showButton:{
            type:Boolean,
            default:true
        },
        name:{
            type:String,
            default:'Suspended Tickets'
        },
        defaultAmount:{
            type:Number,
        },
        numberDataLabel:{
            type:String,
            default:''
        },
        buttonClass:{
            type:String,
            default:'button buttonprimary bottom10'
        }
        
    },
    mixins:[format, datamethods,securityhelper],
    data(){
        return{
            ticketList:[],
            ticketId:0,
            trueParam:true
        }
    },
    computed:{
        ...mapFields([
            'responseMessage',
            'pleaseWait',
            'businessDayId',
 
        ]),
        
    },
    methods:{
        getRemoveString(name){
            return 'Do you want to remove the ticket for ' + name + '?'
        },
        
        async Remove(ID){
            this.responseMessage=null
            console.log('incancel')
            let req = {
                ticket:{
                    id:ID
                }
            }
            var response = await this.callAPI(req,'cancelticket')
            if(response.Successful){
                this.$emit('removeticket', ID)
                this.gettickets()
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        ticketclass(g){
            let c = "guestdetails panel-inner"
            if(g.id==this.ticketId){
                c=c+" color-highlight"
            }else{c=c+" color-inner"}
            return c
        },
        selectTicket(id){
            console.log('sel ' + id)
            this.ticketId=id
        },
        start(){
            this.gettickets()

        },
        async gettickets(){
            this.inPayment=true
            let req = {
                businessDayId:this.businessDayId
            }
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'getsuspendedtickets')
            //handle response
        
            if(response.Successful){
                this.ticketList=response.ticketList
                this.$refs.mymodal.openModal()
                if(this.ticketList.length>0){
                    this.selectTicket(this.ticketList[this.ticketList.length-1].id)
                }
            }else{
            this.responseMessage = response.Message
            }
            this.pleaseWait=false;
                    
        },
        go(){
            this.$emit('gototicket',this.ticketId)
            this.$refs.mymodal.closeModal()
        },


        //void
        getVoidString(name){
                return 'Do you want to void the ticket for ' + name + '?'
            },
        checkprocess_terminaltest(ID){
                this.savedTicketId=ID
                //stash this so we can go to it after it works
                if(this.canAccess_allowOverride('POS','Void Transaction')){
                    //console.log('already has access')
                    this.process_terminaltest(ID)
                }

            },
            overrideSuccessful(){
                //console.log('override!!! call success function')
                this.process_terminaltest(this.savedTicketId)
            },

            async process_terminaltest(ID){
                //if we aren't integrated, then skip to the after
       
                let req = {
                    ticket:{
                        id:ID,
                    },
                    isVoid:true
                }
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'terminal')

                //handle response
                if(response.Successful){
                this.terminalInfo=response

                //if no payment then move on
                if(this.terminalInfo.payment==null){
                    console.log('no pmt')
                    this.voidticket(ID); //do the final processing
                    return;
                }
                else if (this.terminalInfo.payment.transactionAmount==null){
                    //console.log('pmt amt 0')
                    this.voidticket(ID); //do the final processing
                    return;
                }

                //call 
                let req2=this.terminalInfo.payment
                let headers = this.terminalInfo.headers
                //headers
                for(let i=0;i < headers.length;i++){
                    this.AddHeaderToTerminalAPI(headers[i].key, headers[i].value)
                }
                
                var response2 = await this.callAPI_Terminal(req2,this.terminalInfo.endpointString)
                //console.log(response2)
                this.completeTerminal(response2, ID)
                
                }else{
                this.responseMessage = response.Message
                }
                this.pleaseWait=false;
               

                
            },
            async completeTerminal(response, ID){
                let req = {
                    ticket:{id:ID},
                    response:response,
                    isVoid:true
                }
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'terminalcomplete')

                //handle response
                if(response.Successful){
                
                    this.voidticket(ID) //finish!
                }else{
                    this.responseMessage = response.Message
                    this.$emit('refreshticketrebuild',this.responseMessage)
                    
                }
            },
            async voidticket(ID){
                this.responseMessage=null
                console.log('invoid')
                let req = {
                    ticket:{
                        id:ID
                    }
                }
                var response = await this.callAPI(req,'voidticket')
                if(response.Successful){
                    //this.$emit('removeticket', ID)
                    this.gettickets()
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            
    }
}

</script>

<style>
.susprecord{
    text-align:left;
    display:grid;
    grid-template-columns: 8fr 2fr 1fr;
}
</style>





























