<template>
  <div class="outerDiv" :style="configStyle">

      <svheader @logout="logout()" class="header"  @selectLocation="selLocation" @gototicket="gototicket" @suspendticket="suspendticket" @removeticket="removeticket"/>

        <loginuser ref="loginuser" v-if="!loggedIn"  />
       
        <div class="main" v-if="loggedIn && businessDayId!=-1">
           
            <Summary panelIndex="0" @completetransaction="completetransaction"/>
            <Summary panelIndex="1" />
            <Summary panelIndex="2" />
            <Summary panelIndex="3" />

            <GuestPanel panelIndex="0" @refreshticket="refreshticketstart" ref="guest" @checkforopen="checkforopen"/>
            <CartPanel panelIndex="1" ref="cart" @refreshticket="refreshticket" @refreshticketrebuild="refreshticketrebuild"/>
            <PayPanel panelIndex="2" ref="pay" @refreshticket="refreshticket" @refreshticketrebuild="refreshticketrebuild"/>
            <ActionPanel panelIndex="3" @startover="startover" />

           <!--  <HistoryPanel /> -->
        </div>
        <!-- <button @click="getReceiptData">receipt!</button>
        <button @click="OpenDrawer">drawer!</button> -->
  </div>
</template>

<script>

import {mapFields} from 'vuex-map-fields'
import datamethods from '../services/datamethods.js'
import format from '../style/format.js'
import style from '../style/style.js'
import svheader from '../components/header.vue'
import loginuser from '../components/login/loginuser.vue'
import Summary from "../components/screens/Summary.vue";
import GuestPanel from "../components/screens/GuestPanel.vue";
import CartPanel from "../components/screens/CartPanel.vue";
import PayPanel from "../components/screens/PayPanel.vue";
import ActionPanel from "../components/screens/ActionPanel.vue";
import HistoryPanel from "../components/screens/HistoryPanel.vue";
export default {
    components:{
        svheader,

        GuestPanel,
        CartPanel,
        PayPanel,
        ActionPanel,
        Summary,
        HistoryPanel,
        loginuser
    },
    mixins:[ format,style, datamethods],
    computed:{
        ...mapFields([
            'loggedInUser',
            'username',
            'oldpassword',
            'loggedIn',
            'location',
            'availableCartItemTypes',
            'availableCartItems',
            'selectedCartItemType',
            'pleaseWait',
            'businessDayId',
            'terminalId',
            'responseMessage',
            'ticket',
            'receiptIP',
            'imageBasePath'
 
        ]),
        
    },
    data(){
        return{
            receiptData:null,
            isGoTo:false
        }
    },
    methods:{
      completetransaction(){
        console.log('try to complete')
        this.$refs.pay.process_terminaltest()
      },
      removeticket(id){
        if(id==this.ticket.id){
          this.$store.dispatch('startOver');
        }
      },
      async suspendticket(){
        this.inPayment=true
        let req = {
            ticket:this.ticket
          }
          //call api
          this.$store.dispatch('beforeAPI');
          var response = await this.callAPI(req,'suspendticket')
          //handle response
      
          if(response.Successful){
            this.$store.dispatch('startOver');
          }else{
            this.responseMessage = response.Message
          }
          this.pleaseWait=false;
      },
      gototicket(ticketId){
        //console.log('gototicket ' + ticketId)
        this.ticket.id=ticketId
        this.isGoTo=true
        this.refreshticket()
      },
      startover(){
        
      },
      checkforopen(){
        this.selLocation();
      },
      logout(){
        this.loggedIn=false
      },
      selLocation(){
        this.getBusinessDay();
        if(window.SELECT_PRODUCTS_BY_GUEST){
          //wait...we'll get products once we get a guest
        }
        else if(window.USE_PRODUCT_GROUPS){
          this.getAvailableGroups();
        }else{
          this.getAvailableItemTypes();
          this.getAvailableItems();
        }
        
        
      },
      async getBusinessDay(){
        let req = {
            locationId:this.location.id,
            terminalId:this.terminalId
        }  
          //call api
          this.$store.dispatch('beforeAPI');
          var response = await this.callAPI(req,'businessday')
          //handle response
      
          if(response.Successful){
            this.businessDayId=response.ResponseInteger
            if (this.businessDayId==-1){
              this.responseMessage = "This Location is NOT open"
            }else{this.responseMessage="OPEN!"}
          }else{
            this.responseMessage = response.Message
          }
          this.pleaseWait=false;
      },
        async getAvailableItemTypes(){
          let req = {
            locationId:this.location.id
        }  
          //call api
          this.$store.dispatch('beforeAPI');
          var response = await this.callAPI(req,'producttype')
          //handle response
      
          if(response.Successful){
            
            let tmpItems= response.productTypeList
            
            if (tmpItems == undefined) {
              let noneItem = dataModel.NoItemsItem();
              this.availableCartItemTypes.push(noneItem);
            } else {
              this.availableCartItemTypes = tmpItems;
            }
            if(this.availableCartItemTypes.length>0){
              this.selectedCartItemType=this.availableCartItemTypes[0].id
              this.availableCartItemTypes[0].selected=true
            }else{this.selectedCartItemType=0}
          }else{
            this.responseMessage = response.Message
          }
          this.pleaseWait=false;
      },
      async getAvailableItems(){
        let req = {
            locationId:this.location.id
        }      
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'product')
        //handle response
     
        if(response.Successful){
          
          let tmpItems= response.productList
           
          if (tmpItems == undefined) {
            let noneItem = dataModel.NoItemsItem();
            this.availableCartItems.push(noneItem);
          } else {
            this.availableCartItems = tmpItems;
          }

      
        }else{
          this.responseMessage = response.Message
        }
        this.pleaseWait=false;
      },
      async getAvailableGroups(){
        let req = {
            locationId:this.location.id
        }  
          //call api
          this.$store.dispatch('beforeAPI');
          var response = await this.callAPI(req,'productgroup')
          //handle response
      
          if(response.Successful){
            
            let tmpItems= response.ProductGroupList
            
            if (tmpItems == undefined) {
              let noneItem = dataModel.NoItemsItem();
              this.availableCartItemTypes.push(noneItem);
            } else {
              this.availableCartItemTypes = tmpItems;
            }
            if(this.availableCartItemTypes.length>0){
              this.selectedCartItemType=this.availableCartItemTypes[0].id
              this.availableCartItemTypes[0].selected=true
            }else{this.selectedCartItemType=0}
          }else{
            this.responseMessage = response.Message
          }
          this.pleaseWait=false;
      },
      async refreshticketstart(){
        this.isGoTo=false
        this.refreshticket(true)
      },
      refreshticketrebuild(msg){
        this.isGoTo=true
        this.refreshticket(false, msg)
      },
      async refreshticket(start,msg){
          let req = {
            ticket:{
              id:this.ticket.id
            }
          }  
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'ticket')
            //handle response
        
            if(response.Successful){
              this.ticket=response.ticket

              //start cart!
              if(start){
                this.$refs.cart.startCart()
              }else if (this.isGoTo){
                this.isGoTo=false
                this.$store.dispatch('buildCart');
                this.$refs.cart.refreshCart()
                this.$refs.pay.updateTotals()
                this.$refs.guest.refreshGuest()
               }else{
                //console.log('call refreshcart')
                //this.$store.dispatch('buildCart');
                this.$refs.cart.refreshCart()
                //this.$refs.pay.updateTotals()
              }
              if (msg!=null){this.responseMessage=msg}
            }else{
              this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        getImageBasePath(){
            this.imageBasePath = window.VUE_CONFIG_API + 'personimage/'
             // console.log("img path " + this.imageBasePath)
        },
    },
    
    mounted(){

      //fix favicon and title
      if(window.FAVICON!=undefined){
        const favicon = document.getElementById('favicon')
        favicon.href='./../' + window.FAVICON
      }
      if(window.PAGE_TITLE!=undefined){
        const pagetitle = document.getElementById('pagetitle')
        pagetitle.innerText=window.PAGE_TITLE
      }
      

        let urlParams = new URLSearchParams(window.location.search)
        let tmp = urlParams.get('email')
        if(tmp!=null){
            this.username = tmp;
        }

        let tmpp = urlParams.get('temp')
        if(tmpp!=null){
            this.oldpassword = tmpp;
            this.$nextTick(()=>{
            //this.changepass()
            this.$refs.loginuser.changepassword();
            });
        }

        let tmp2 = urlParams.get('location')
        if(tmp2!=null){
            this.location.id = tmp2;
        }

        let tmp3 = urlParams.get('terminal')
        if(tmp3!=null){
            this.terminalId = tmp3;
        }

        //receipt ip
        let tmp4 = urlParams.get('receipt')
        if(tmp4!=null){
            this.receiptIP = tmp4;
        }else{
          if(window.RECEIPT_IP!=undefined){
            this.receiptIP=window.RECEIPT_IP
          }
        }
     
        this.setColors();
        this.$store.dispatch('setupPanels')
        
        this.getImageBasePath();
        //for testing
        /* this.loggedIn=true
        this.loggedInUser={
            name:'Test User',
            email:'test@test.com',
            locationName:'Test Location'
        } */

        //this.getAvailableItemTypes()
    },
    
}
</script>

<style lang="scss">
@import "@/assets/stylebase.scss";

:root {
  --color-valid: rgb(128, 168, 128);
  --color-header-border: #13395d;
 

  --font-size-default: 14px;
  --font-size-biggest: 22px;
  --font-size-bigger: 18px;
  --font-color-default: black;
  --font-color-disabled: darkgray;


--background-color-disabled: lightgray;

  /* --background-color-default: white;
  --background-color-disabled: lightgray;
  --background-color-background: #c2b9b9;
  --background-color-inner: rgb(198, 205, 207);
  --background-color-inner-highlight: rgb(250, 246, 198);
  --background-color-button: #346278; /* #2c75aa; 
  --background-color-button-highlight: #8aa6b4; */

  --margin-default: 10px;
  --margin-bigger: 20px;
  --margin-smaller:5px;
  --margin-button: 10px 10px 20px 10px;

  --padding-default: 10px;
  --padding-mid:15px;
  --padding-bigger: 20px;
  --padding-smaller: 5px;

  --margin-none: 0px;

  --border-radius-default: 2px;
  --border-radius-less:0px;
  --border-radius-more:4px;
  --border-default: 1px solid #c2b9b9;
  --border-thick: 4px solid #c2b9b9;

  --box-shadow-default: 6px 6px 6px #6e6868;
  --box-shadow-smaller: 2px 2px 2px #6e6868;
}

/* full screen */
@media screen and (min-width: 812px) {
    .outerDiv{
      background-color:var(--background-color);
        margin:0px 0px 0px 0px;
        height:98vh;
        display:grid;
        grid-template-rows:170px auto 80px;
        grid-template-areas: 
            "header"
            "center"
            "footer";
    }
    .entry{
        flex-direction: row;
    }

    
}
/* phone */
@media screen and (max-width: 812px) {
    .outerDiv{
        margin:0px 0px 0px 0px;
        height:100vh;
        display:grid;
        grid-template-rows:10px auto 105px;
        grid-template-areas: 
            "header"
            "center"
            "footer";
    }
    .entry{
        flex-direction: column;
    }
    
}
.header{
    grid-area:header
}

.main {
    grid-area: center;

    background-color: var(--background-color);

    display: grid;
    grid-template-columns: auto 280px 3fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      "history guest full"
      "history cart full"
      "history payment full"
      "history action full";
  }



.rightstack {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin: 0px 10px 60px 10px;
  color: var(--color-header-border);
}

.guest {
  grid-area: guest;
}
.lineitem {
  grid-area: cart;
}
.action {
  grid-area: action;
}
.payment {
  grid-area: payment;
}
.history {
  grid-area: history;
}

/*PANELS*/
.panel {
  border-radius: var(--border-radius-more);
  padding: var(--padding-default);
  background-color: var(--frame-color);
}
.panel-inactive {
  margin: 10px 20px 10px 10px;
}
.panel-active {
  margin: 10px -10px 10px 10px;
  border: var(--border-default);
  box-shadow: var(--box-shadow-default);
}

.panel-valid {
  border-style: solid;
  border-color: var(--color-valid);
  border-width: 0px 0px 0px 15px;
  /* puts green bar to left of tab */
}
.panel-invalid {
  background-color: var(--frame-color);
  border-color: var(--frame-color);
  border-width: 0px 0px 0px 15px;
}
.panel-unavailable {
  background-color: var(--background-color-disabled);
  color: var(--font-color-disabled);
}
.panel-full {
  grid-area: full;
  background-color: var(--frame-color);
  margin: 10px 10px 10px 0px;
  padding: var(--padding-bigger);
  box-shadow: var(--box-shadow-default);
}
.panel-inner {
  padding: var(--padding-default);
  margin: var(--margin-default);
  border: var(--border-default);
  border-radius: var(--border-radius-default);
  // box-shadow: var(--box-shadow-smaller);
  //background-color: var(--background-color-inner);
}
.color-inner{
  //background-color: var(--background-color-inner);
  border: var(--border-default);
}
.color-highlight{
 // background-color: var(--background-color-inner);
  border: var(--border-thick);
  border-color:var(--button-color);
  //font-weight: bolder;
}
/* input:focus{
    border:1px solid var(--highlight-color);
    outline:none;
  } */
.scroll{
  height:80%;
    overflow-x:hidden;
    overflow-y:scroll;
}
/*BUTTONS*/
.btn {
  background-color: var(--button-color);
  color: var(--light-text-color);
  margin: var(--margin-default);
  border: none;
  font-size: inherit;
  border-radius: var(--border-radius-default);
  padding: var(--padding-bigger);
  
}
.btn-red{
  background-color:red;
  color: var(--light-text-color);
  margin: var(--margin-default);
  border: none;
  font-size: inherit;
  border-radius: var(--border-radius-default);
  padding: var(--padding-smaller);
}
.btn-small {
  background-color: var(--button-color);
  color: var(--light-text-color);
  margin: var(--margin-default);
  border: none;
  font-size: inherit;
  border-radius: var(--border-radius-default);
  padding: var(--padding-smaller);
}
.btn:hover {
  background-color: var(--highlight-button-color);
}
.btn-small:hover {
  background-color: var(--highlight-button-color);
}
.paymentbutton {
  background-color: var(--color-payment);
  color: var(--light-text-color);
  min-width:120px;
}
.cashpaymentbutton{
  background-color: var(--color-cash);
  color: var(--light-text-color);
  min-width:120px;
}
.nextbutton {
  grid-area: next;
  text-align: right;
}

/*TEXT*/
.text {
  margin: var(--margin-default);
  font-size: var(--font-size-default);
}
.title {
  margin: var(--margin-default);
  font-size: var(--font-size-bigger);
}
.subtitletab{
  font-size: var(--font-size-default);
}
.subtitletabalert{
  font-size: var(--font-size-default);
  color:red;
}
.subtitle {
  font-size: var(--font-size-bigger);
}
.text-right {
  text-align: right;
}
.bold {
  font-weight: bold;
}
hr {
  color: var(--inactive-color);
}

/*IMAGES*/
.headerimage {
  width: 50%;
  margin: var(--margin-bigger);
}

/*TEXTBOXES*/
.textbox {
  font-size: inherit;
  margin: var(--margin-default);
  border-radius: var(--border-radius-default);
}

/*TABLES*/
table,
th,
td {
  border: var(--border-default);
  padding: var(--padding-default);
  font-weight: normal;
}
td {
  text-align: center;
}
th {
  font-size: var(--font-size-bigger);
}
table {
  border-collapse: collapse;
  margin: var(--margin-default);
  width: 92%;
}


.totaltext{
  font-size:larger;
  
}
.paycolor{
  color:var(--color-payment);
  font-weight:bold;
}
.discountcolor{
  color:red;
}
.eligiblemessage{
  color:var(--color-payment);
}
</style>