<template>
    <div class="pmtdiv">
        <smallbutton :toplabel="name" @click="start()" v-if="showButton" :buttonclass="buttonClass"/>
        <linkbutton :toplabel="name" @click="start()" v-if="!showButton"/>
        <!--  MODAL -->
          <actionmodalwide ref="mymodal"> 
            <template v-slot:body>
                <form ref="balform">
                    
                    <label class="titlelabel">{{name}}</label>
                    <br/>
                    <div>
                        <label v-if="ticketList.length==0">There are no tickets today</label>
                    </div>
                       
                        <div :class="ticketclass(t)" v-for="t in ticketList" :key="t.id" :value="t.id" >
                            <div class="ticketrecord">
                                <label class="text subtitle ticketfield" for>{{toDate(t.endDateTime)}}</label>
                                <label class="text subtitle ticketfield" for>{{t.guestName}}</label>
                                <label class="text subtitle ticketfield leftjustify" for>{{t.productServiceName}}</label>
                                <label class="text subtitle ticketfield leftjustify" for>{{t.employeeName}}</label>
                                <label class="text subtitle ticketfield rightjustify" for>$ {{t.total}}</label>
                                <label class="text subtitle ticketfield leftjustify" for>{{t.statusName}}</label>
                                <ticketdetail :ticketId="t.id"/>
                                <confirm  :showLink="trueParam" :body="getRemoveString(t.guestName)" title="Void Ticket" @yes="checkprocess_terminaltest(t.id)" v-if="isvoidable(t)"/>
                                <linkbutton toplabel="Receipt" @click="getReceiptData(t.id)"/>
                            </div>
                            
                        </div>        
                    
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                    <securityoverride ref="override"  @overridesuccessful="overrideSuccessful"/>

                    <div hidden>
                        <div  class="receiptclass" id="receiptdatadiv" v-if="receiptData!=null" >
                        <label>{{receiptData}}</label>
                        </div>
                        <button ref="printthereceipt" class="btn actionbutton narrowbutton"  v-print="'#receiptdatadiv'" v-if="receiptData!=null" type="button">Print Receipt</button>
                    </div>
                    
                </form>
                
                
            </template>
          </actionmodalwide>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../style/format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import actionmodalwide from '../controls/actionmodalwide.vue'
    import formentry from '../controls/entry.vue'
    import datamethods from '../../services/datamethods.js'
    import confirm from '../actions/confirm.vue'
    import securityoverride from '../actions/securityoverride.vue'
    import securityhelper from '../../services/securityhelper.js'
    import ticketdetail from '../actions/ticketdetail.vue'
    import print from 'vue-print-nb'
    export default {
        name:'transactions',
        components:{
            smallbutton,
            actionmodalwide,
            formentry,
            linkbutton,
            confirm,
            securityoverride,
            ticketdetail
        },
        props:{
           
            showButton:{
                type:Boolean,
                default:true
            },
            name:{
                type:String,
                default:"Today's Tickets"
            },
            defaultAmount:{
                type:Number,
            },
            numberDataLabel:{
                type:String,
                default:''
            },
            buttonClass:{
                type:String,
                default:'button buttonprimary bottom10'
            }
            
        },
        mixins:[format, datamethods,securityhelper],
        directives:{
            print
        },
        data(){
            return{
                ticketList:[],
                ticketId:0,
                trueParam:true, 
                terminalInfo:null,
                savedTicketId:0,
                receiptData:null
            }
        },
        computed:{
            ...mapFields([
                'responseMessage',
                'pleaseWait',
                'location',
                'terminalId'
     
            ]),
            integratedCards(){
      
                if(window.INTEGRATED_CARDS==undefined){return false}
                else{return window.INTEGRATED_CARDS}
            
            },
        },
        methods:{
            isvoidable(t){
                return t.statusName!='Voided' && t.total >0
            },
            async getReceiptData(ticketid){
                let req = {
                    ticket:{
                        id:ticketid
                    }
                }  
                    //call api
                    this.$store.dispatch('beforeAPI');
                    var response = await this.callAPI(req,'ticketreceipt')
                    //handle response
                
                    if(response.Successful){
                    this.receiptData=response.StringResponse
                    this.pleaseWait=false;
                    this.receipt() //print!
                    
                    this.$nextTick(() => {
                        this.$refs.printthereceipt.click()
                    })
                    
                    }else{
                    this.responseMessage = response.Message
                    }
                    this.pleaseWait=false;
            },
            toDate(dt){
                return this.$options.filters.formatTime(dt);
            },
            getRemoveString(name){
                return 'Do you want to void the ticket for ' + name + '?'
            },
            async Remove(ID){
                this.responseMessage=null
                console.log('incancel')
                let req = {
                    ticket:{
                        id:ID
                    }
                }
                var response = await this.callAPI(req,'cancelticket')
                if(response.Successful){
                    this.$emit('removeticket', ID)
                    this.gettickets()
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            checkprocess_terminaltest(ID){
                this.savedTicketId=ID
                //stash this so we can go to it after it works
                if(this.canAccess_allowOverride('POS','Void Transaction')){
                    //console.log('already has access')
                    this.process_terminaltest(ID)
                }

            },
            overrideSuccessful(){
                //console.log('override!!! call success function')
                this.process_terminaltest(this.savedTicketId)
            },

            async process_terminaltest(ID){
                //if we aren't integrated, then skip to the after
                if(this.integratedCards==false){
                    this.voidticket(ID); 
                    return
                }
                let req = {
                    ticket:{
                        id:ID,
                    },
                    isVoid:true
                }
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'terminal')

                //handle response
                if(response.Successful){
                this.terminalInfo=response

                //if no payment then move on
                if(this.terminalInfo.payment==null){
                    console.log('no pmt')
                    this.voidticket(ID); //do the final processing
                    return;
                }
                else if (this.terminalInfo.payment.transactionAmount==null){
                    //console.log('pmt amt 0')
                    this.voidticket(ID); //do the final processing
                    return;
                }

                //call 
                let req2=this.terminalInfo.payment
                let headers = this.terminalInfo.headers
                //headers
                for(let i=0;i < headers.length;i++){
                    this.AddHeaderToTerminalAPI(headers[i].key, headers[i].value)
                }
                
                var response2 = await this.callAPI_Terminal(req2,this.terminalInfo.endpointString)
                //console.log(response2)
                this.completeTerminal(response2, ID)
                
                }else{
                this.responseMessage = response.Message
                }
                this.pleaseWait=false;
               

                
            },
            async completeTerminal(response, ID){
                let req = {
                    ticket:{id:ID},
                    response:response,
                    isVoid:true
                }
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'terminalcomplete')

                //handle response
                if(response.Successful){
                
                    this.voidticket(ID) //finish!
                }else{
                    this.responseMessage = response.Message
                    this.$emit('refreshticketrebuild',this.responseMessage)
                    
                }
            },
            async voidticket(ID){
                this.responseMessage=null
                console.log('invoid')
                let req = {
                    ticket:{
                        id:ID
                    }
                }
                var response = await this.callAPI(req,'voidticket')
                if(response.Successful){
                    //this.$emit('removeticket', ID)
                    this.gettickets()
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            ticketclass(g){
                let c = "guestdetails panel-inner  color-inner"
                /* if(g.id==this.ticketId){
                    c=c+" color-highlight"
                }else{c=c+" color-inner"} */
                return c
            },
            selectTicket(id){
                console.log('sel ' + id)
                this.ticketId=id
            },
            start(){
                this.gettickets()
    
            },
            async gettickets(){
                this.inPayment=true
                let req = {
                    locationId:this.location.id,
                    terminalId:this.terminalId
                }
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'gettodaytickets')
                //handle response
            
                if(response.Successful){
                    this.ticketList=response.ticketList
                    this.$refs.mymodal.openModal()
                    if(this.ticketList.length>0){
                        this.selectTicket(this.ticketList[this.ticketList.length-1].id)
                    }
                }else{
                this.responseMessage = response.Message
                }
                this.pleaseWait=false;
                        
            },
            receipt(drawer){
     
     var receiptText
     if(drawer){
       receiptText="drawer"
     }else{
       receiptText= this.receiptData
     }
     //test receipt
     var ePosDev = new epson.ePOSDevice();
     var printer = null
     var ipAddress = this.receiptIP;

     //if we don't have an ip...say so
     if(ipAddress==''){
       console.log("no receipt printer IP available. Please check URL and configuration")
       return
     }

     var port = this.receiptPort;
     ePosDev.connect(ipAddress, port, callback_connect);

     function callback_connect(data) {
       if(data == 'OK') {
         ePosDev.createDevice('local_printer', ePosDev.DEVICE_TYPE_PRINTER, {'crypto' : true, 'buffer' : false}, cbCreateDevice_printer);
       } else {
         console.log(data);
       }
     }
     function cbCreateDevice_printer(devobj, retcode) {
       if( retcode == 'OK' ) {
         printer = devobj;
         console.log(this.printer)
         executeAddedCode();
       } else {
         console.log(retcode);
       }
     }
     function executeAddedCode() {
       console.log('thinking about printing')
       if(receiptText=="drawer"){
         console.log('drawer please')
         printer.addSound(printer.PATTERN_A, 1, 1000);
       }else{
         console.log('receipt please')
         printer.addText(receiptText)
         printer.addCut(printer.CUT_FEED);
       }
       printer.send();
     }
   },
                
        }
    }
    
    </script>
    
    <style>
    .ticketfield{
        min-width:100px;
    }
    .ticketrecord{
        display:grid;
        grid-template-columns: 1fr 1fr 4fr 1fr 1fr 2fr 2fr 2fr 1fr;
    }
    .leftjustify{
        text-align: left;
    }
    .rightjustify{
        text-align: right;
    }
    .receiptclass{
  margin-top:120px;
  white-space: pre-wrap;
} 
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    